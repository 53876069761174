import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    <>
    <header>
      <nav class="navbar navbar-dark text-white">
        <div class="container-fluid">
         
         <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
           <span class="navbar-toggler-icon"></span>
        </button>
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body text-center">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item">
                  <a class="nav-link active py-3 rough" href="/">HOME</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active py-3 rough" href="/menu">MENU</a>
                </li>

              </ul>
            
            </div>
          </div>
        </div>
      </nav>
    </header>
    </>
  );
}

export default withRouter(Navigation);
