import React from "react";

function Home() {
  return (
    <main class="flex-shrink-0">

      <div class="img-move-wrapper">
        <img class="img-move img-fluid background-image"></img>
        <img class="img-move img-fluid background-image-pizza"></img>
        <img class="img-move img-fluid background-image-wings"></img>
        <img class="img-move img-fluid background-image-town"></img>
        <img class="img-fluid background-image-empty"></img>
      </div>


      <div class="container">
        
        <div class="text-center pt-3">
        </div>
        <div class="text-center pt-3">
          
          <div class="col-lg-12">
            
            <p class="rough">
            OPEN EVERY DAY<br/>
            10:30AM - 11PM
            </p>
            <p class="rough py-2">
              <a href="./menu">VIEW MENU</a>
              <br/>
              <a href="tel:617-825-3340">617-825-3340</a>
            </p>
            
            <a href="https://ordering.chownow.com/order/9907/locations?add_cn_ordering_class=true" target="_blank" class="btn btn-order rough-small">ORDER ONLINE</a>

      
            <p class="pt-3">
              <img class="payment-icon" src="/images/visa.svg"></img>
              <img class="payment-icon" src="/images/mastercard.svg"></img>
            </p>
            <p class="tiny-text">
            95¢ credit card handling fee on deliveries. We will temporarily pre-authorize your credit card 15% above your total in order
          to allow for any gratuity that you may wish to give your driver. This overcharge may show up temporarily on your online statement.
  
          Prices and menu items are subject to change without notice. Offers are for a limited time only.
          Consuming raw or undercooked meats, poultry, seafood or shellfish may increase risk of foodborne illness.
            </p>
            
            <p class="rough-small">
            WE DELIVER FAST • CATERING AVAILABLE
            </p>
            <p class="rough-small">
            1750 Dorchester Ave.<br/>
            Dorchester, MA
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
