import React, { useEffect } from "react";


function Menu() {



  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://menus.singleplatform.com/widget';
    script.setAttribute('data-hide_cover_photo', 'true');
    script.setAttribute('data-hide_disclaimer', 'true');
    script.setAttribute('data-location', 'dimitrios-house-of-pizza');
    script.setAttribute('data-api_key', 'ke09z8icq4xu8uiiccighy1bw');
    script.setAttribute('id', 'singleplatform-menu');
    script.setAttribute('data-item_background_color', '#fff1d7');
    script.setAttribute('data-menu_desc_background_color', '#f5bc54');
    script.setAttribute('data-primary_background_color', '#f5bc54');
    script.setAttribute('data-section_desc_background_color', '#f5bc54');
    script.setAttribute('data-section_title_background_color', '#f5bc54');
    script.setAttribute('data-primary_font_family', 'Barlow, sans-serif');
    script.setAttribute('item-hr_background_color', '#f5bc54');
    script.setAttribute('data-hide_attribution', 'true');
    script.setAttribute('data-stylesheet', 'https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Ubuntu:wght@300;400;500&display=swap');



    script.async = true;
    const element = document.querySelector('#m1');
    element.appendChild(script);
    



    return () => {
      element.removeChild(script);
    };
  }, []);


  return (
      <div class="container">
          <div class="col-lg-8 pt-3 mx-auto text-center">
              <a href="./"><img class ="pb-2 logo-small" src="./images/logo-small.png"></img></a>
              <div id="m1" class="m"></div>
              <div id="m2" class="m"></div>
              <div class="rough pt-2">
                <p>CALL NOW</p>
                <a href="tel:617-825-3340">617-825-3340</a>
                <br/>
                <p class="pt-2">- or -</p>
              
              </div>
              <a href="https://ordering.chownow.com/order/9907/locations?add_cn_ordering_class=true" target="_blank" class="btn btn-order mb-4 rough-small">ORDER ONLINE</a>
              <br/>
              <br/>
          </div>
      </div>
  );
}

export default Menu;
