import React from "react";

function Footer() {
  return (
      <footer class="footer mt-auto pt-3 pb-4 text-white">
        <div>
          <p class="m-0 text-center fw-bold py-2 rough-small">
            <a href="./"> HOME </a> | <a href="https://ordering.chownow.com/order/9907/locations?add_cn_ordering_class=true" target="_blank">ORDER ONLINE</a> | 
            <a href="https://apps.apple.com/us/app/dimitrios-pizza-wings/id1314588453" target="_blank"> IPHONE APP </a>


            
          </p>

        </div>
      </footer>
  );
}

export default Footer;
